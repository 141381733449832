<template>
  <div
    :class="[
      currentStep == 0
        ? 'bg-bible'
        : currentStep == 1
        ? 'bg-register'
        : 'bg-books',
    ]"
    class="bg-cover h-36 relative"
  >
    <div
      v-if="church.churchName"
      class="absolute top-3 right-3 px-1 rounded-2xl bg-black bg-opacity-30 cursor-pointer"
      @click="$emit('changeStep', 1)"
    >
      <span class="text-white font-semibold">{{ church.churchName }}</span>
    </div>
    <div
      v-if="currentStep > 1 || (!authenticatedUser?.dni && currentStep > 0)"
      class="m-3 bg-white bg-opacity-20 absolute top-5 rounded-full border-2 p-2 text-white"
      @click="$emit('changeStep', currentStep - 1)"
    >
      <img src="icons/chevron-left-solid.svg" alt="" width="10" height="10" />
    </div>
    <div
      class="text-center h-36 pt-8 text-3xl text-white font-bold bg-black bg-opacity-60"
    >
      {{
        currentStep == 0
          ? ""
          : currentStep == 1 && authenticatedUser?.dni
          ? "Mis datos"
          : currentStep == 1
          ? "Registro"
          : currentStep == 2
          ? "Suscripción"
          : currentStep == 3
          ? "Carrito"
          : "Pago"
      }}
    </div>
  </div>

  <div v-if="currentStep != 0" class="flex flex-row justify-center">
    <div
      v-for="step in steps"
      :key="step.id"
      class="flex flex-col -mt-12 w-24 lg:w-48 items-center cursor-pointer opacity-100"
      @click="[step.id != 5 ? $emit('changeStep', step.id) : null]"
    >
      <div class=" w-8 h-6 flex items-center justify-center z-10">
        <div
          :class="[
            currentStep == step.id
              ? 'bg-aces text-light-gray h-8 w-8'
              : 'bg-aces text-light-gray text-opacity-0 w-2 h-2',
          ]"
          class="rounded-full transform ease-in-out duration-500 font-bold flex justify-center items-center"
        >
          {{ currentStep >= step.id ? step.id : null }}
          <div
            v-if="step.id != 1"
            :class="[
              currentStep == step.id
                ? 'border-aces border-t w-10 mr-5'
                : currentStep > step.id
                ? 'border-aces border-t w-10 mr-2'
                : 'border-aces border-dashed border-t opacity-30 w-12',
            ]"
            class="absolute top-50 right-0 lg:w-24 transform ease-in-out duration-500"
          ></div>
          <div
            v-if="step.id != 4"
            :class="[
              currentStep == step.id
                ? 'border-aces border-t w-10 ml-5'
                : currentStep > step.id
                ? 'border-aces border-t w-12 ml-2'
                : 'border-aces border-dashed border-t opacity-30 w-12',
            ]"
            class="absolute top-50 left-0 lg:w-24 transform ease-in-out duration-500"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import axios from "axios";

export default {
  props: {
    authenticatedUser: {
      type: Object,
      default: () => {},
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      steps: [
        { id: 1, name: "Datos" },
        { id: 2, name: "Suscripción" },
        { id: 3, name: "Confirmación" },
        { id: 4, name: "Pagos" },
      ],
    };
  },
  emits: ["changeStep"],
  setup(props) {
    const church = ref({});

    watchEffect(async () => {
      if (props.authenticatedUser.churchClientId) {
        await axios
          .get(`Churches/ById/${props.authenticatedUser.churchClientId}`)
          .then((res) => {
            church.value = res.data;
          })
          .catch((err) => console.log("err", err.response));
      }
    });

    return { church };
  },
};
</script>

<style lang="scss" scoped></style>
