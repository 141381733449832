<template>
  <div>
    <form
      @submit.prevent="validateCompletedField"
      class="flex flex-col gap-2 bg-white rounded-2xl p-6 shadow-md"
    >
      <div class="text-center font-medium pb-3 px-4">
        Por ser tu primera vez, para crear tu suscripción necesitarás completar
        los siguientes datos.
      </div>
      <div>
        <label class="block text-sm font-light px-3">Nombre </label>
        <input
          v-model="person.firstName"
          type="text"
          required
          class="mt-1 p-1 px-4 focus:outline-none focus:border-dark-gray block w-full bg-white rounded-full shadow-md border border-light-gray text-lg"
          @focus="searching = false"
        />
      </div>
      <div>
        <label class="block text-sm font-light px-3">Apellido </label>
        <input
          v-model="person.lastName"
          type="text"
          required
          class="mt-1 p-1 px-4 focus:outline-none focus:border-dark-gray block w-full bg-white rounded-full shadow-md border border-light-gray text-lg"
          @focus="searching = false"
        />
      </div>

      <div>
        <label class="block text-sm font-light px-3 text-gray-700 "
          >Tipo de Documento</label
        >
        <Listbox v-model="selectedDocType">
          <div class="relative my-1">
            <ListboxButton
              class="relative w-full py-1 pl-4 pr-10 text-left bg-white rounded-full shadow-md cursor-default focus:outline-none focus:border-dark-gray border border-light-gray text-lg"
            >
              <span class="block truncate">{{ selectedDocType }}</span>
              <span
                class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
              >
                <SelectorIcon
                  class="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                class="absolute w-full py-1 mt-1 overflow-auto  bg-white z-10 rounded-2xl shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  v-slot="{ active, selected }"
                  v-for="docType in docList"
                  :key="docType"
                  :value="docType"
                  as="template"
                >
                  <li
                    :class="[
                      active ? 'text-amber-900 bg-amber-100' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-10 pr-4',
                    ]"
                  >
                    <span
                      :class="[
                        selected ? 'font-medium' : 'font-normal',
                        'block truncate',
                      ]"
                      >{{ docType }}</span
                    >
                    <span
                      v-if="selected"
                      class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                    >
                      <CheckIcon class="w-5 h-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>

      <div>
        <label class="block text-sm font-light px-3">Número de Documento</label>
        <input
          v-model="person.documentNumber"
          type="number"
          required
          class="mt-1 p-1  px-4 focus:outline-none focus:border-dark-gray block w-full bg-white rounded-full shadow-md border border-light-gray text-lg"
          @focus="searching = false"
          @input="validateData"
        />
      </div>

      <div class="col-span-6 sm:col-span-4">
        <label class="block text-sm font-light px-3">Email</label>
        <input
          v-model="person.email"
          type="email"
          autocomplete="email"
          required
          class="mt-1 p-1 px-4 focus:outline-none focus:border-dark-gray block w-full bg-white rounded-full shadow-md border border-light-gray text-lg"
          @focus="searching = false"
        />
      </div>

      <div>
        <label class="block text-sm font-light px-3"
          >Celular
          <i class="px-1 text-xs"
            >(diez dígitos, sin cero ni quince) *Se te enviará un código de
            seguridad</i
          ></label
        >
        <div class="flex">
          <input
            v-model="person.phoneNumber"
            type="tel"
            pattern="[0-9]{10}"
            required
            class="mt-1 p-1 px-2 focus:outline-none focus:border-dark-gray block w-full bg-white rounded-full shadow-md border border-light-gray text-lg"
            @focus="searching = false"
            @input="validateData"
          />
        </div>
      </div>

      <div>
        <label class="block text-sm font-light px-3">Provincia</label>
        <Listbox v-model="selectedProvince">
          <div class="relative mt-1">
            <ListboxButton
              @focus="(searching = false), filterChurchList('')"
              class="relative w-full py-1 pl-4 pr-10 text-left bg-white rounded-full shadow-md cursor-default focus:outline-none focus:border-dark-gray border border-light-gray text-lg"
            >
              <span class="block truncate">{{
                selectedProvince?.provinceName
              }}</span>
              <span
                class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
              >
                <SelectorIcon
                  class="w-5 h-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                class="absolute w-full py-1 mt-1 overflow-auto  bg-white z-10 rounded-2xl shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  v-slot="{ active, selected }"
                  v-for="province in provinces"
                  :key="province"
                  :value="province"
                  as="template"
                >
                  <li
                    :class="[
                      active ? 'text-amber-900 bg-amber-100' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-10 pr-4',
                    ]"
                  >
                    <span
                      :class="[
                        selected ? 'font-medium' : 'font-normal',
                        'block truncate',
                      ]"
                      >{{ province?.provinceName }}</span
                    >
                    <span
                      v-if="selected"
                      class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                    >
                      <CheckIcon class="w-5 h-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>

      <div>
        <Listbox v-model="selectedChurch">
          <label class="block text-sm font-light px-3"
            >Iglesia
            <i class="px-1 text-xs">
              *Asegurate de que tu iglesia esté seleccionada.</i
            ></label
          >
          <input
            id="searchBox"
            value=""
            type="text"
            required
            autocomplete="off"
            class="mt-1 p-1 px-4 focus:outline-none focus:border-dark-gray block w-full bg-white rounded-full shadow-md border border-light-gray text-lg"
            @input="filterChurchList($event.target.value)"
          />
          <div v-show="searching && filteredList.length > 0">
            <transition
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                static
                class="absolute w-4/5 py-1 mt-1 overflow-auto text-sm bg-white rounded-2xl shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  v-slot="{ active, selected }"
                  v-for="church in filteredList"
                  :key="church"
                  :value="church"
                  as="template"
                  @click="selectChurchOnClick"
                >
                  <li
                    :class="[
                      active ? 'text-amber-900 bg-amber-100' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-12 pr-4',
                    ]"
                  >
                    <span
                      :class="[
                        selected ? 'font-medium' : 'font-normal',
                        'block truncate',
                      ]"
                      >{{ church.churchName }}</span
                    >
                    <span
                      v-if="selected"
                      class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                    >
                      <CheckIcon class="w-5 h-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>

      <div>
        <label class="block text-sm font-light px-3">Coordinador</label>
        <input
          :placeholder="selectedChurch?.coordinatorName"
          readonly
          class="mt-1 p-1 px-4 focus:outline-none focus:border-dark-gray block w-full bg-white rounded-full shadow-md border border-light-gray text-lg"
        />
      </div>
      <form
        v-if="showConfirmationForm"
        @submit.prevent="checkCode(code)"
        class="flex justify-center flex-col mx-3"
      >
        <p class="text-sm text-left text-gray-500 m-2">
          Hemos enviado un codigo de confirmacion al número
          {{ person.phoneNumber }}
        </p>
        <label class="block font-light text-gray-700">Codigo</label>

        <input
          v-model="code"
          type="text"
          required
          :class="[
            wrongCode ? 'border-red-warning border-opacity-75' : 'border-light-gray',
          ]"
          class="mt-1 px-3 py-2 focus:outline-none focus:border-dark-gray bg-white rounded-full shadow-md border lg:text-lg"
        />
        <p v-if="wrongCode" class="text-red-warning text-sm">
          El codigo no coincide con el enviado por mensaje
        </p>
        <button
          type="submit"
          class="my-4 p-4 py-2  text-center font-medium text-white bg-aces border border-transparent rounded-full focus:outline-none focus:border-dark-gray uppercase lg:text-lg"
        >
          Confirmar
        </button>
      </form>

      <!-- {{ this.selectChurch }} -->
      <button
        v-if="!showConfirmationForm"
        type="submit"
        class="w-full px-4 py-1 mt-4  text-center font-medium text-white bg-aces border border-transparent rounded-full focus:outline-none focus:border-dark-gray uppercase text-lg"
      >
        Crear Usuario
      </button>
    </form>
    <!-- modal de usuario existente. Desde aca podes pedir un nuevo codigo de acceso -->
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black bg-opacity-75" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  <div class="pl-2 flex items-center justify-between">
                    <h2 class="text-2xl font-medium text-gray-900">
                      Usuario existente
                    </h2>
                    <button
                      type="button"
                      class="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400"
                      @click="
                        () => {
                          isOpen = false;
                          showConfirmationModal = false;
                          timeOut = false;
                        }
                      "
                    >
                      <span class="sr-only">Close menu</span>
                      <XIcon class="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </DialogTitle>
                <div class="mt-4">
                  <p class="text-sm text-gray-500">
                    <span v-if="notYetAuthUser.phoneNumber">
                      El {{ notYetAuthUser.docType }}
                      {{ notYetAuthUser.documentNumber }} ya existe para
                      {{ notYetAuthUser.firstName }}
                      {{ notYetAuthUser.lastName }}, con el número de teléfono
                      ******** {{ notYetAuthUser.phoneNumber.substring(7) }}.
                    </span>
                    <span v-else>
                      El {{ notYetAuthUser.docType }}
                      {{ notYetAuthUser.documentNumber }} ya existe para
                      {{ notYetAuthUser.firstName }}
                      {{ notYetAuthUser.lastName }}, pero no posee un número de
                      teléfono asociado.
                    </span>
                  </p>
                  <template v-if="notYetAuthUser.phoneNumber">
                    <p class="my-4 text-sm text-gray-500">
                      ¿Desea enviar un sms para validar la cuenta?
                    </p>
                    <button
                      @click="
                        () => {
                          showConfirmationModal = true;
                          timeOut = true;
                          $emit('resend');
                        }
                      "
                      class="my-2 w-full p-4 py-2 font-medium rounded-full focus:outline-none  uppercase lg:text-xl"
                      :class="[
                        timeOut
                          ? 'text-dark-gray bg-light-gray cursor-not-allowed'
                          : 'bg-aces cursor-pointer text-white',
                      ]"
                      :disabled="timeOut"
                    >
                      Enviar
                    </button>
                    <form
                      v-if="showConfirmationModal"
                      @submit.prevent="
                        () => {
                          $emit('check', code);
                        }
                      "
                      class="flex justify-center flex-col"
                    >
                      <p class="text-sm text-left text-gray-500">
                        Ingresa el código de confirmación que te enviamos al
                        teléfono
                      </p>
                      <label class="block font-light text-gray-700 mt-2"
                        >Código</label
                      >

                      <input
                        v-model="code"
                        type="text"
                        required
                        :class="[
                          isCodeOk
                            ? 'border-red-warning border-opacity-75'
                            : 'border-light-gray',
                        ]"
                        class="mt-1 px-3 py-2 focus:outline-none focus:border-dark-gray bg-white rounded-full shadow-md border lg:text-lg"
                      />
                      <p v-if="isCodeOk" class="text-red-warning text-sm">
                        El código no coincide con el enviado por mensaje
                      </p>
                      <button
                        type="submit"
                        class="my-4 p-4 py-2  text-center font-medium text-white bg-aces border border-transparent rounded-full focus:outline-none focus:border-dark-gray uppercase lg:text-lg"
                      >
                        Confirmar
                      </button>
                    </form>
                  </template>
                  <i class="my-4 px-1 text-xs">
                    *Ante cualquier inconveniente, por favor comuniquese con su
                    coordinador
                  </i>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon, XIcon } from "@heroicons/vue/solid";
import { watchEffect } from "vue";

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    CheckIcon,
    SelectorIcon,
    XIcon,
  },
  props: {
    isCodeOk: { type: Boolean, default: false },
  },
  setup() {
    const isLoading = ref(false);
    const person = ref({});
    const provinces = ref([]);
    const selectedProvince = ref({});
    const churches = ref([]);
    const selectedChurch = ref(null);
    const docList = ["DNI", "LC", "LE"];
    const selectedDocType = ref(docList[0]);
    const isOpen = ref(false);
    const timeOut = ref(false);
    const showConfirmationModal = ref(false);
    const getprovinces = async () => {
      try {
        isLoading.value = true;
        const { data } = await axios.get(`Provinces/all`);
        provinces.value = data;
        selectedProvince.value = data[0];
      } catch (err) {
        console.log(err);
      }
    };
    getprovinces();

    watchEffect(async () => {
      if (selectedProvince.value != null) {
        const { data } = await axios.get(
          `Churches/byProvince?provinceId=${selectedProvince.value.provinceId}`
        );
        selectedChurch.value = null;
        document.getElementById("searchBox").value = "";
        churches.value = data;
      }
    });

    watchEffect(() => {
      if (selectedChurch.value != null) {
        person.value.churchClientId = selectedChurch.value.churchClientId;
      }
    });
    watchEffect(() => {
      person.value.docType = selectedDocType.value;
    });

    return {
      person,
      provinces,
      selectedProvince,
      churches,
      selectedChurch,
      docList,
      selectedDocType,
      isOpen,
      timeOut,
      showConfirmationModal,
    };
  },
  data() {
    return {
      filteredList: [],
      searching: false,
      showConfirmationForm: false,
      wrongCode: false,
      notYetAuthUser: {},
    };
  },
  methods: {
    filterChurchList(fas) {
      if (fas !== "") this.searching = true;
      var f = this.churches.filter((x) =>
        x.churchName.toLowerCase().includes(fas.toLowerCase())
      );
      return (this.filteredList = f);
    },
    selectChurchOnClick() {
      this.searching = false;
      document.getElementById("searchBox").value = !this.selectedChurch
        ? null
        : this.selectedChurch.churchName;
    },
    validateData() {
      this.person.phoneNumber = this.person.phoneNumber.replace(" ", "");
      this.person.phoneNumber = this.person.phoneNumber.replace("-", "");
      this.person.phoneNumber = this.person.phoneNumber.replace(".", "");
      this.person.documentNumber = this.person.documentNumber.replace(" ", "");
      this.person.documentNumber = this.person.documentNumber.replace("-", "");
      this.person.documentNumber = this.person.documentNumber.replace(".", "");
    },
    async checkUserExists(dni, type) {
      await axios
        .get(`Clients/searchClient?dni=${dni}&docType=${type}`)
        .then((res) => {
          console.log("quiero abrir el modal");
          this.isOpen = true;
          this.notYetAuthUser = res.data;
          this.$emit("setUser", res.data);
        })
        .catch(async () => await this.sendValidationCode());
    },
    validateCompletedField() {
      var churchWrited = document.getElementById("searchBox");
      if (!this.selectedChurch) {
        churchWrited.value = "";
        return churchWrited.focus();
      } else if (this.selectedChurch.churchName != churchWrited.value) {
        churchWrited.value = "";
        this.selectedChurch = null;
        return churchWrited.focus();
      }
      this.checkUserExists(this.person.documentNumber, this.person.docType);
    },
    async sendValidationCode() {
      this.person["subscriptionCode"] = Math.floor(1000 + Math.random() * 9000);
      const text = `Nadie de ACES te va a solicitar este dato. No lo compartas. Tu codigo de seguridad es ${this.person.subscriptionCode}`;
      // console.log(text);
      await axios
        .post(
          `Sms/sendCode?phoneNumber=${this.person.phoneNumber}&message=${text}`,
          {}
        )
        .then(async (res) => {
          if ((await res.data) == true) {
            this.showConfirmationForm = true;
          }
        })
        .catch((err) => console.log(err.response));
    },
    checkCode(code) {
      if (code == this.person.subscriptionCode) {
        return this.$emit("send", this.person);
      }
      this.wrongCode = true;
    },
  },
};
</script>
