<template>
  <div v-if="isShow">
    <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
      <div v-for="banco in bancos" :key="banco.name">
        <div
          class="w-full flex items-center justify-between p-6 space-x-6 rounded-xl bg-white shadow-lg my-2 text-dark-gray h-30 text-xs"
        >
          <img
            :src="banco.logo"
            loading="lazy"
            class="w-10 h-10 bg-gray-300 rounded-full flex-1-truncate"
          />
          <div class="pr-8 flex-shrink-0">
            <div class="font-semibold truncate">{{ banco.name }}</div>
            <div class="text-xs font-extralight">
              CBU: {{ banco.cbu }}
              <br />
              Cuenta: {{ banco.account }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="text-sm text-center m-2">CUIT: 30-50011757-1</p>
  </div>
</template>
<script>
export default {
  name: "Bancos",
  data() {
    return {
      bancos: [
        {
          name: "Galicia",
          cbu: "0070056620000000325255",
          account: "325/2-056/5",
          logo: "GaliciaLogoB.png",
        },
        {
          name: "HSBC",
          cbu: "1500015200006503000400",
          account: "0650-30004/0",
          logo: "HSBCLogoB.png",
        },
        {
          name: "Macro",
          cbu: "2850528230094157965171",
          account: "352809415796517",
          logo: "MacroLogo.png",
        },
        {
          name: "Nación",
          cbu: "0110097620009700217703",
          account: "0970021770 Suc. 2507",
          logo: "NacionLogo.png",
        },
        {
          name: "Santander",
          cbu: "0720082320000000483290",
          account: "082-004832/9",
          logo: "SantanderLogo.png",
        },
      ],
    };
  },
  props: {
    isShow: Boolean,
  },
};
</script>
