<template>
  <div class="-mt-9 mx-4 lg:mx-32 relative">
    <!-- Si ya esta autenticado -->

    <div
      v-if="authenticatedUser?.documentNumber"
      class="p-3  bg-white rounded-xl lg:px-8 "
    >
      <div :class="[isLoading == true ? 'pt-52 lg:pt-96' : '']">
        <div
          :class="[
            isLoading == true
              ? ' loader my-auto mx-auto  ease-linear rounded-full border-8 border-t-8 h-32 w-32 lg:h-64 lg:w-64'
              : '',
          ]"
        ></div>
      </div>
      <div
        :class="[isLoading == false ? 'opacity-100' : 'opacity-0']"
        class="flex flex-col justify-between gap-3 transition duration-1000 ease-in-out"
      >
        <div class="text-center font-medium m-1">
          Usted se encuentra autenticado como:
        </div>
        <!-- <pre>{{ editedUser }}</pre> -->
        <div class="grid grid-cols-1 gap-2 lg:grid-cols-2 lg:gap-6">
          <div>
            <label class="block text-sm font-light px-3 text-gray-700 "
              >Nombre
            </label>
            <div>
              <input
                required
                :value="authenticatedUser.firstName"
                type="text"
                name="firstName"
                :readonly="!availableToEdit"
                :class="[
                  availableToEdit
                    ? 'border border-aces font-medium'
                    : 'border border-light-gray',
                ]"
                class="mt-1 px-4 py-1 focus:outline-none focus:border-dark-gray block w-full rounded-full shadow-md lg:text-lg"
                @change="editUser"
              />
            </div>
          </div>
          <div>
            <label class="block text-sm font-light px-3 text-gray-700"
              >Apellido
            </label>
            <input
              required
              :value="authenticatedUser.lastName"
              type="text"
              name="lastName"
              :readonly="!availableToEdit"
              :class="[
                availableToEdit
                  ? 'border border-aces font-medium'
                  : 'border border-light-gray',
              ]"
              class="mt-1 px-4 py-1 focus:outline-none focus:border-dark-gray block w-full  rounded-full shadow-md lg:text-lg"
              @change="editUser"
            />
          </div>
          <div>
            <label class="block text-sm font-light px-3 text-gray-700 "
              >Tipo de Documento</label
            >
            <Listbox v-model="selectedDocType" @click="editUser" required>
              <div class="relative my-1">
                <ListboxButton
                  :class="[
                    availableToEdit
                      ? 'border border-aces font-medium'
                      : 'border border-light-gray',
                  ]"
                  class="relative w-full py-1 pl-4 pr-10 text-left bg-white rounded-full shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500"
                >
                  <span class="block truncate">{{ selectedDocType }}</span>
                  <span
                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                  >
                    <SelectorIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>
                <div v-show="openListBox">
                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0"
                  >
                    <ListboxOptions
                      static
                      class="absolute w-full py-1 mt-1 overflow-auto bg-white z-10 rounded-2xl shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none "
                    >
                      <ListboxOption
                        v-slot="{ active, selected }"
                        v-for="docType in docList"
                        :key="docType"
                        :value="docType"
                        as="template"
                      >
                        <li
                          :class="[
                            active
                              ? 'text-amber-900 bg-amber-100'
                              : 'text-gray-900',
                            'cursor-default select-none relative py-2 pl-10 pr-4',
                          ]"
                        >
                          <span
                            :class="[
                              selected ? 'font-medium' : 'font-normal',
                              'block truncate',
                            ]"
                            >{{ docType }}</span
                          >
                          <span
                            v-if="selected"
                            class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                          >
                            <CheckIcon class="w-5 h-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </div>
            </Listbox>
          </div>
          <div>
            <label class="block text-sm font-light px-3 text-gray-700"
              >DNI
            </label>
            <input
              required
              :value="authenticatedUser.documentNumber"
              type="number"
              name="documentNumber"
              :readonly="!availableToEdit"
              :class="[
                availableToEdit
                  ? 'border border-aces font-medium'
                  : 'border border-light-gray',
              ]"
              class="mt-1 px-4 py-1 focus:outline-none focus:border-dark-gray block w-full  rounded-full shadow-md lg:text-lg"
              @change="editUser"
              @input="validateData"
            />
          </div>
          <div>
            <label class="block text-sm font-light px-3 text-gray-700"
              >Email
            </label>
            <input
              required
              :value="authenticatedUser.email"
              type="text"
              name="email"
              :readonly="!availableToEdit"
              :class="[
                availableToEdit
                  ? 'border border-aces font-medium'
                  : 'border border-light-gray',
              ]"
              class="mt-1 px-4 py-1 focus:outline-none focus:border-dark-gray block w-full  rounded-full shadow-md lg:text-lg"
              @change="editUser"
            />
          </div>
          <div>
            <label class="block text-sm font-light px-3 text-gray-700 lg:"
              >Celular
            </label>
            <input
              required
              :value="authenticatedUser.phoneNumber"
              type="tel"
              name="phoneNumber"
              :readonly="!availableToEdit"
              autocomplete="given-name"
              :class="[
                availableToEdit
                  ? 'border border-aces font-medium'
                  : 'border border-light-gray',
              ]"
              class="mt-1 px-4 py-1 focus:outline-none focus:border-dark-gray block w-full bg-white rounded-full shadow-md lg:text-lg"
              @change="editUser"
              @input="validateData"
            />
          </div>

          <div v-if="availableToEdit">
            <label class="block text-sm font-light px-3">Provincia</label>
            <Listbox v-model="selectedProvince">
              <div class="relative mt-1">
                <ListboxButton
                  @focus="(searching = false), filterChurchList('')"
                  class="relative w-full py-1 pl-4 pr-10 text-left bg-white rounded-full shadow-md cursor-default focus:outline-none focus:border-dark-gray text-lg border border-aces font-medium"
                >
                  <span class="block truncate">{{
                    selectedProvince?.provinceName
                  }}</span>
                  <span
                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                  >
                    <SelectorIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    class="absolute w-full py-1 mt-1 overflow-auto  bg-white z-10 rounded-2xl shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    <ListboxOption
                      v-slot="{ active, selected }"
                      v-for="province in provinces"
                      :key="province"
                      :value="province"
                      as="template"
                      @change="editUser"
                    >
                      <li
                        :class="[
                          active
                            ? 'text-amber-900 bg-amber-100'
                            : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-10 pr-4',
                        ]"
                      >
                        <span
                          :class="[
                            selected ? 'font-medium' : 'font-normal',
                            'block truncate',
                          ]"
                          >{{ province?.provinceName }}</span
                        >
                        <span
                          v-if="selected"
                          class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                        >
                          <CheckIcon class="w-5 h-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>
          <div v-else>
            <label class="block text-sm font-light px-3">Provincia</label>
            <input
              v-model="selectedProvince.provinceName"
              type="text"
              readonly
              autocomplete="given-name"
              class="mt-1 px-4 py-1 focus:outline-none focus:border-dark-gray block w-full bg-white rounded-full shadow-md border border-light-gray lg:text-lg"
            />
          </div>

          <div v-if="availableToEdit">
            <Listbox v-model="selectedChurch">
              <label class="block text-sm font-light px-3">Iglesia </label>
              <input
                id="searchBox"
                value=""
                type="text"
                required
                autocomplete="off"
                class="mt-1 p-1 px-4 focus:outline-none focus:border-dark-gray block w-full bg-white rounded-full shadow-md text-lg"
                :class="[
                  availableToEdit
                    ? 'border border-aces font-medium'
                    : 'border border-light-gray',
                ]"
                @input="filterChurchList($event.target.value)"
              />
              <div v-show="searching && filteredList.length > 0">
                <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    :static="searchText.length > 0 ? true : false"
                    class="absolute w-2/5 py-1 mt-1 overflow-auto text-sm bg-white rounded-2xl shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    <ListboxOption
                      v-slot="{ active, selected }"
                      v-for="church in filteredList"
                      :key="church"
                      :value="church"
                      as="template"
                      @click="selectChurchOnClick"
                    >
                      <li
                        :class="[
                          active
                            ? 'text-amber-900 bg-amber-100'
                            : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-12 pr-4',
                        ]"
                      >
                        <span
                          :class="[
                            selected ? 'font-medium' : 'font-normal',
                            'block truncate',
                          ]"
                          >{{ church.churchName }}</span
                        >
                        <span
                          v-if="selected"
                          class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                        >
                          <CheckIcon class="w-5 h-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
          </div>
          <div v-else>
            <label class="block text-sm font-light px-3 text-gray-700 lg:"
              >Iglesia
            </label>
            <input
              :value="
                selectedChurch === null
                  ? church.churchName
                  : selectedChurch.churchName
              "
              type="text"
              readonly
              autocomplete="given-name"
              class="mt-1 px-4 py-1 focus:outline-none focus:border-dark-gray block w-full bg-white rounded-full shadow-md border border-light-gray lg:text-lg"
            />
          </div>

          <div>
            <label class="block text-sm font-light px-3 text-gray-700"
              >Coordinador
            </label>
            <input
              :value="
                selectedChurch === null
                  ? church.coordinatorName
                  : selectedChurch.coordinatorName
              "
              type="text"
              readonly
              class="mt-1 px-4 py-1 focus:outline-none focus:border-dark-gray block w-full bg-white rounded-full shadow-md border border-light-gray lg:text-lg"
            />
          </div>
        </div>
        <button
          type="button"
          :class="[
            availableToEdit
              ? 'text-white bg-dark-gray'
              : 'text-dark-gray bg-light-gray',
          ]"
          class="my-3 p-4 py-2 font-medium rounded-full focus:outline-none  uppercase lg:text-xl"
          @click="saveEditedUser"
        >
          {{ availableToEdit ? "Guardar Cambios" : "Editar Datos" }}
        </button>
        <button
          type="button"
          class=" p-4 py-2 font-medium text-white bg-aces border border-transparent rounded-full focus:outline-none focus:border-dark-gray uppercase lg:text-xl"
          @click="deleteSession"
        >
          Salir
        </button>
        <div class="w-full flex justify-center p-3">
          <img loading="lazy" src="logo.png" alt="Aces Logo" class="h-8 w-14" />
        </div>
      </div>
    </div>

    <!-- Si no esta autenticado -->
    <div
      v-else-if="currentStep == 0"
      class="flex justify-center flex-col align-center text-center gap-2 lg:gap-6"
    >
      <div class="rounded-xl bg-white p-3 shadow-md lg:px-32">
        <div
          :class="[isLoading == false ? 'opacity-100' : 'opacity-0']"
          class="flex flex-col justify-between gap-3 transition duration-1000 ease-in-out"
        >
          <p class="font-bold p-2 text-lg">¡Bienvenido a Suscripciones!</p>
          <p class="font-regular">
            Si es tu primera vez, crea tu pedido de suscripción en 4 simples
            pasos.
          </p>
          <button
            class="mt-6 mb-8 py-2 w-full font-medium text-white bg-aces border border-transparent rounded-full focus:outline-none focus:border-dark-gray uppercase lg:text-lg"
            @click="$emit('changeStep', 1)"
          >
            ¡Crea tu suscripción!
          </button>
        </div>
      </div>

      <div class="rounded-xl bg-white mt-3 p-3 shadow-md lg:px-32">
        <div
          :class="[isLoading == false ? 'opacity-100' : 'opacity-0']"
          class="flex flex-col justify-between gap-3 transition duration-1000 ease-in-out"
        >
          <p class="font-bold pt-2 text-lg">¿Ya tenés un pedido?</p>
          <form
            @submit.prevent="checkUserExists(dni, selectedDocType)"
            class="flex justify-center flex-col m-3 align-center
        "
          >
            <label class="block font-light text-gray-700 lg:"
              >Tipo de Documento</label
            >
            <Listbox v-model="selectedDocType">
              <div class="relative my-1">
                <ListboxButton
                  :disabled="notYetAuthUser?.documentNumber"
                  class="relative w-full py-2 pl-4 pr-10 text-left bg-white rounded-full shadow-md cursor-default focus:outline-none focus:border-dark-gray sm:text-sm border border-light-gray lg:text-lg"
                >
                  <span class="block truncate">{{ selectedDocType }}</span>
                  <span
                    class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                  >
                    <SelectorIcon
                      class="w-5 h-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </ListboxButton>

                <transition
                  leave-active-class="transition duration-100 ease-in"
                  leave-from-class="opacity-100"
                  leave-to-class="opacity-0"
                >
                  <ListboxOptions
                    class="absolute text-left w-full py-1 mt-1 px-2 overflow-auto bg-white z-10 rounded-2xl shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    <ListboxOption
                      v-slot="{ active, selected }"
                      v-for="docType in docList"
                      :key="docType"
                      :value="docType"
                      as="template"
                    >
                      <li
                        :class="[
                          active
                            ? 'text-amber-900 bg-amber-100'
                            : 'text-gray-900',
                          'cursor-default select-none relative py-2 px-12',
                        ]"
                      >
                        <span
                          :class="[
                            selected ? 'font-medium' : 'font-normal',
                            'block truncate',
                          ]"
                          >{{ docType }}</span
                        >
                        <span
                          v-if="selected"
                          class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                        >
                          <CheckIcon class="w-5 h-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>

            <label class="block font-light text-gray-700"
              >Número de Documento</label
            >
            <input
              v-model="dni"
              type="number"
              required
              class=" mt-2 py-2 px-3 focus:outline-none focus:border-dark-gray bg-white rounded-full shadow-md border border-light-gray lg:text-lg"
              :disabled="notYetAuthUser?.documentNumber"
            />
            <p v-if="userNotFound" class="text-red-warning text-sm">
              No se encontró el usuario, revise el tipo y número de documento.
            </p>
            <button
              id="user"
              v-if="!notYetAuthUser?.documentNumber"
              type="submit"
              class="my-2 py-2 font-medium text-white bg-aces border border-transparent rounded-full focus:outline-none focus:border-dark-gray uppercase lg:text-lg"
            >
              Buscar usuario
            </button>
          </form>
          <i
            class="text-sm text-center text-gray-500 m-2 text-red-warning"
            v-if="notYetAuthUser?.documentNumber && !notYetAuthUser.phoneNumber"
            >*Tu teléfono está incompleto, por favor comunicate tu coordinador
            de publicaciones</i
          >
          <form
            v-else-if="
              notYetAuthUser?.documentNumber &&
                !authenticatedUser?.documentNumber
            "
            @submit.prevent="checkCode(code)"
            class="flex justify-center flex-col mx-3"
          >
            <div
              class="text-sm text-left text-gray-500 m-2 flex flex-col space-y-2 md:space-y-0"
            >
              <p>
                Ingresá el código enviado por SMS. Si lo perdiste, te lo
                <a
                  :disabled="timeOut"
                  :class="[
                    timeOut
                      ? 'text-light-gray'
                      : 'underline font-medium cursor-pointer text-hyperlink',
                  ]"
                  @click="resendCode"
                >
                  reenviamos
                </a>
                al número ...{{ notYetAuthUser.phoneNumber.substring(7) }}
              </p>
              <p>
                <i
                  >*Podes usar el código existente si ya se te ha enviado uno</i
                >
              </p>
            </div>
            <label class="block font-light text-gray-700">Código</label>

            <input
              v-model="code"
              type="number"
              required
              :class="[
                wrongCode
                  ? 'border-red-warning border-opacity-75'
                  : 'border-light-gray',
              ]"
              class="mt-1 px-3 py-2 focus:outline-none focus:border-dark-gray bg-white rounded-full shadow-md border lg:text-lg"
            />
            <p v-if="wrongCode" class="text-red-warning text-sm">
              El código no coincide con el enviado por mensaje
            </p>
            <button
              type="submit"
              class="my-4 p-4 py-2  text-center font-medium text-white bg-aces border border-transparent rounded-full focus:outline-none focus:border-dark-gray uppercase lg:text-lg"
            >
              Confirmar
            </button>
          </form>
        </div>
        <div class="w-full flex justify-center p-3 mt-6">
          <img src="logo.png" alt="Aces Logo" class="h-8 w-14" />
        </div>
      </div>
    </div>

    <NewUser
      v-else-if="!authenticatedUser?.documentNumber && currentStep == 1"
      @send="createUser"
      @resend="resendCode"
      @check="(c) => checkCode(c)"
      @setUser="(u) => (notYetAuthUser = u)"
      :isCodeOk="wrongCode"
    />
  </div>
</template>

<script>
import NewUser from "./NewUser.vue";
import axios from "axios";
import { ref, watchEffect } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import { createToast } from "mosha-vue-toastify";

export default {
  components: {
    NewUser,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    SelectorIcon,
    CheckIcon,
  },
  setup(props) {
    const docList = ["DNI", "LC", "LE"];
    const church = ref({});
    const isLoading = ref(false);
    const wrongCode = ref(false);

    const provinces = ref([]);
    const selectedProvince = ref({});
    const churches = ref([]);
    const selectedChurch = ref(null);
    const searchText = ref("");

    const selectedDocType =
      props.authenticatedUser?.docType == null
        ? ref(docList[0])
        : ref(props.authenticatedUser?.docType);

    isLoading.value = true;

    const findChurch = async () => {
      if (props.authenticatedUser?.churchClientId) {
        await axios
          .get(`Churches/ById/${props.authenticatedUser.churchClientId}`)
          .then((res) => {
            church.value = res.data;
          });
      }
    };
    findChurch();

    setTimeout(() => {
      isLoading.value = false;
    }, 1000);

    watchEffect(async () => {
      if (church.value.provinceId !== undefined) {
        try {
          isLoading.value = true;
          const { data } = await axios.get(`Provinces/all`);
          provinces.value = data;

          selectedProvince.value = data.find(
            (x) => x.provinceId === church.value.provinceId
          );
        } catch (err) {
          console.log(err);
        }
      }
    });

    watchEffect(async () => {
      if (selectedProvince.value.provinceId !== undefined) {
        await axios
          .get(
            `Churches/byProvince?provinceId=${selectedProvince.value.provinceId}`
          )
          .then((res) => {
            churches.value = res.data;
            selectedChurch.value = res.data.find(
              (x) => x.churchClientId === church.value.churchClientId
            );
          });
      }
    });

    return {
      docList,
      church,
      selectedDocType,
      isLoading,
      wrongCode,

      provinces,
      selectedProvince,
      churches,
      selectedChurch,
      searchText,
    };
  },
  props: {
    authenticatedUser: {
      type: Object,
      default: () => {},
    },
    currentStep: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      dni: "",
      code: "",
      notYetAuthUser: {},
      availableToEdit: false,
      openListBox: false,
      userNotFound: false,
      editedUser: { ...this.authenticatedUser },
      timeOut: false,

      filteredList: [],
      searching: false,
    };
  },
  methods: {
    async saveEditedUser() {
      if (!this.availableToEdit) return (this.availableToEdit = true);

      await axios
        .put(`Clients/update`, this.editedUser)
        .then((res) => {
          // console.log(res);
          this.$emit("update", res.data);
        })
        .catch((err) => {
          if (err.response.status === 406) {
            this.selectedDocType = this.authenticatedUser.docType;
            createToast(
              `Ya existe un usuario con el documento ${this.editedUser.docType} ${this.editedUser.documentNumber}`,
              {
                position: "top-center",
                type: "danger",
                transition: "zoom",
              }
            );
          }
          console.log(err.response);
        });
      return (this.availableToEdit = false);
    },
    editUser(e) {
      console.log("e", e);
      if (e.type == "click")
        if (!this.availableToEdit) {
          return;
        } else {
          this.openListBox = !this.openListBox;
          return (this.editedUser.docType = this.selectedDocType);
        }
      this.editedUser[e.target.name] = e.target.value;
      console.log("editedUser", this.editedUser);
    },
    async createUser(e) {
      const res = await axios.post("Clients/create", e).catch((err) => {
        if (err.response.status === 406) {
          createToast(
            `Ya existe un usuario con el documento ${e.docType} ${e.documentNumber}`,
            {
              position: "top-center",
              type: "danger",
              transition: "zoom",
            }
          );
        }
        console.log(err.response);
      });

      if (res?.status === 201) {
        this.$emit("update", res.data);
      }
    },
    async checkUserExists(dni, type) {
      const res = await axios
        .get(`Clients/searchClient?dni=${dni}&docType=${type}`)
        .catch((err) => {
          if (err.response?.status) {
            createToast("El usuario no existe.", {
              position: "top-center",
              type: "danger",
              transition: "zoom",
            });
            return (this.userNotFound = true);
          } //return (this.userNotFound = true);
        });
      if (res && res.status === 200) {
        return (this.notYetAuthUser = res.data);
      }
      this.userNotFound = false;
    },
    async checkCode(code) {
      await axios
        .get(
          `Clients/checkCode?clientId=${this.notYetAuthUser.clientId}&subscriptionCode=${code}`
        )
        .then((res) => {
          // console.log(res);
          if (res.data == true) {
            this.$emit("update", this.notYetAuthUser);
            this.notYetAuthUser = {};
            return this.$emit("changeStep", 2);
          } else {
            return (this.wrongCode = true);
          }
        });
    },
    async resendCode() {
      this.timeOut = true;
      setTimeout(() => {
        this.timeOut = false;
      }, 30000);
      await axios.post(
        `Sms/resendMessage?dni=${this.notYetAuthUser.documentNumber}&docType=${this.notYetAuthUser.docType}`
      );
    },
    deleteSession() {
      localStorage.removeItem("authenticatedUser");
      localStorage.removeItem("cartProducts");
      localStorage.removeItem("currentStep");
      this.$emit("update", {});
      this.$emit("changeStep", 0);
    },
    validateData() {
      this.person.phoneNumber = this.person.phoneNumber.replace(" ", "");
      this.person.phoneNumber = this.person.phoneNumber.replace("-", "");
      this.person.phoneNumber = this.person.phoneNumber.replace(".", "");

      this.person.documentNumber = this.person.documentNumber.replace(" ", "");
      this.person.documentNumber = this.person.documentNumber.replace("-", "");
      this.person.documentNumber = this.person.documentNumber.replace(".", "");
    },

    ////////////////////////////////////////////////////////
    filterChurchList(fas) {
      if (fas !== "") this.searching = true;
      var f = this.churches.filter((x) =>
        x.churchName.toLowerCase().includes(fas.toLowerCase())
      );
      this.searchText = fas;
      return (this.filteredList = f);
    },
    filterChurchList3(fas) {
      if (fas !== "") this.searching = true;
      var f = this.churches.filter((x) =>
        x.churchName.toLowerCase().includes(fas.toLowerCase())
      );
      return (this.filteredList = f);
    },
    selectChurchOnClick() {
      this.searching = false;
      document.getElementById("searchBox").value = !this.selectedChurch
        ? null
        : this.selectedChurch.churchName;

      this.editedUser = {
        ...this.editedUser,
        churchClientId: this.selectedChurch.churchClientId,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
