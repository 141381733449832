import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import "tailwindcss/tailwind.css";
import "./assets/tailwind.css";

axios.defaults.baseURL = process.env.VUE_APP_URL;
axios.defaults.timeout = 2500;
axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (401 === error.response.status) {
      localStorage.removeItem("token");
      localStorage.setItem("currentStep", 0);
      localStorage.setItem("authenticatedUser", {});
    } else {
      return Promise.reject(error);
    }
  }
);

createApp(App).mount("#app");
