<template>
  <div
    class="-mt-9 pb-12 lg:mx-32 rounded-xl relative"
    :class="[total > 0 ? 'bg-white' : 'bg-background']"
  >
    <div :class="[isLoading == true ? 'pt-52 lg:pt-96' : '']">
      <div
        :class="[
          isLoading == true
            ? ' loader my-auto mx-auto  ease-linear rounded-full border-8 border-t-8 h-32 w-32 lg:h-64 lg:w-64'
            : '',
        ]"
      ></div>
    </div>
    <div
      :class="[isLoading == false ? 'opacity-100' : 'opacity-0']"
      class="transition duration-1000 ease-in-out"
    >
      <p class=" font-medium p-4 text-center bg-white rounded-xl ">
        {{
          isEditable && !existsPayment
            ? "¡Último paso! Elige el medio y realiza el pago de tu suscripción"
            : newPayment.amount == null
            ? "Ya se cerro la campaña y no realizaste un pago"
            : "Detalles de tu pago"
        }}
      </p>
      <div v-if="total > 0" class="mx-2 md:mx-6">
        <TabGroup v-if="(isEditable && !existsPayment) || confirmMPPayment">
          <TabList
            class="flex p-1 space-x-1 bg-white border-aces border-2 rounded-xl"
          >
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  'w-full py-2.5 text-xl leading-5 font-medium rounded-lg',
                  'focus:outline-none',
                  selected ? 'bg-aces shadow text-white' : 'text-xl text-aces',
                ]"
              >
                Transferencia
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  'w-full py-2.5 text-xl leading-5 font-medium rounded-lg',
                  'focus:outline-none',
                  selected ? 'bg-aces shadow text-white' : 'text-xl text-aces',
                ]"
                @click="generateScript"
              >
                Mercado pago
              </button>
            </Tab>
          </TabList>

          <TabPanels class="mt-2">
            <TabPanel
              :class="[
                'bg-white rounded-xl p-3',
                'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
              ]"
            >
              <form @submit.prevent="savePayment">
                <p class="text-center mb-6">
                  Una vez hecha tu transferencia ingresa los siguientes datos
                </p>
                <div class="flex flex-col gap-2">
                  <!-- <div class="grid grid-cols-1 gap-2 lg:grid-cols-2 lg:gap-6"> -->
                  <div>
                    <label class="block text-sm font-light px-3 text-gray-700 "
                      >Banco
                    </label>
                    <Listbox v-model="selectedBank">
                      <div class="relative mt-1">
                        <ListboxButton
                          class="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm"
                        >
                          <span class="block truncate">{{ selectedBank }}</span>
                          <span
                            class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"
                          >
                            <SelectorIcon
                              class="w-5 h-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </ListboxButton>

                        <transition
                          leave-active-class="transition duration-100 ease-in"
                          leave-from-class="opacity-100"
                          leave-to-class="opacity-0"
                        >
                          <ListboxOptions
                            class="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                          >
                            <ListboxOption
                              v-slot="{ active, selected }"
                              v-for="bank in banks"
                              :key="bank"
                              :value="bank"
                              as="template"
                            >
                              <li
                                :class="[
                                  active
                                    ? 'text-amber-900 bg-amber-100'
                                    : 'text-gray-900',
                                  'cursor-default select-none relative py-2 pl-10 pr-4',
                                ]"
                              >
                                <span
                                  :class="[
                                    selected ? 'font-medium' : 'font-normal',
                                    'block truncate',
                                  ]"
                                  >{{ bank }}</span
                                >
                                <span
                                  v-if="selected"
                                  class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                                >
                                  <CheckIcon
                                    class="w-5 h-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              </li>
                            </ListboxOption>
                          </ListboxOptions>
                        </transition>
                      </div>
                    </Listbox>
                  </div>
                  <div>
                    <label class="block text-sm font-light px-3 text-gray-700"
                      >Número de transferencia
                    </label>
                    <input
                      required
                      v-model="newPayment.location"
                      type="text"
                      class="font-medium mt-1 px-4 py-1 focus:outline-none focus:border-dark-gray block w-full  rounded-full shadow-md lg:text-lg"
                    />
                  </div>
                  <div>
                    <label class="block text-sm font-light px-3 text-gray-700 "
                      >Fecha de pago
                    </label>
                    <div>
                      <input
                        required
                        v-model="newPayment.bankOperationDate"
                        type="date"
                        class="font-medium mt-1 px-4 py-1 focus:outline-none focus:border-dark-gray block w-full rounded-full shadow-md lg:text-lg"
                      />
                    </div>
                  </div>
                  <div>
                    <label class="block text-sm font-light px-3 text-gray-700"
                      >Comprobante
                    </label>
                    <input
                      id="file"
                      required
                      type="file"
                      class="font-medium mt-1 px-4 py-1 focus:outline-none focus:border-dark-gray block w-full  rounded-full shadow-md lg:text-lg"
                    />
                  </div>
                </div>
                <div class="mt-6">
                  <div
                    class="flex items-center space-x-2 justify-center my-2 w-full text-black bg-light-gray p-4 py-2 font-medium rounded-full focus:outline-none  uppercase lg:text-xl"
                    disabled
                    v-if="chargeButton"
                  >
                    <span>
                      Cargando
                    </span>
                    <img
                      src="icons/spinner-solid.svg"
                      alt=""
                      class="w-4 h-4 animate-spin"
                    />
                  </div>
                  <button
                    v-else-if="!chargeButton"
                    type="submit"
                    class="my-2 w-full text-white bg-aces p-4 py-2 font-medium rounded-full focus:outline-none  uppercase lg:text-xl"
                  >
                    Cargar pago
                  </button>
                  <div v-if="total > 0">
                    <button
                      type="button"
                      class="my-2 w-full text-white bg-aces p-4 py-2 font-medium rounded-full focus:outline-none  uppercase lg:text-xl"
                      @click="isShowBancos = !isShowBancos"
                    >
                      <span v-if="isShowBancos">Ocultar Datos Bancarios</span>
                      <span v-else>Mostrar Datos Bancarios</span>
                    </button>

                    <Bancos :isShow="isShowBancos" />
                  </div>
                </div>
              </form>
            </TabPanel>
            <TabPanel
              :class="[
                'bg-white rounded-xl p-3',
                'focus:outline-none focus:ring-2 ring-offset-2 ring-offset-blue-400 ring-white ring-opacity-60',
              ]"
            >
              <button
                type="button"
                onclick="checkout.open()"
                class="my-2 w-full text-white bg-blue hover:bg-blue-200 p-4 py-2 font-medium rounded-full focus:outline-none  uppercase lg:text-xl"
              >
                Pagar con Mercado Pago
              </button>
            </TabPanel>
          </TabPanels>
        </TabGroup>
        <div class="flex flex-col gap-2" v-else>
          <div v-if="newPayment.type">
            <label class="block text-sm font-light px-3 text-gray-700"
              >Tipo de pago
            </label>
            <input
              required
              v-model="paymentTypes[newPayment.type]"
              type="text"
              disabled
              class="font-medium mt-1 px-4 py-1 focus:outline-none focus:border-dark-gray block w-full  rounded-full shadow-md lg:text-lg"
            />
          </div>
          <div v-if="newPayment.bank">
            <label class="block text-sm font-light px-3 text-gray-700"
              >Banco
            </label>
            <input
              required
              v-model="newPayment.bank"
              type="text"
              disabled
              class="font-medium mt-1 px-4 py-1 focus:outline-none focus:border-dark-gray block w-full  rounded-full shadow-md lg:text-lg"
            />
          </div>
          <div v-if="newPayment.location">
            <label class="block text-sm font-light px-3 text-gray-700"
              >Número de transferencia
            </label>
            <input
              required
              v-model="newPayment.location"
              type="text"
              disabled
              class="font-medium mt-1 px-4 py-1 focus:outline-none focus:border-dark-gray block w-full  rounded-full shadow-md lg:text-lg"
            />
          </div>
          <div v-if="newPayment.bankOperationDate">
            <label class="block text-sm font-light px-3 text-gray-700"
              >Fecha de pago
            </label>
            <input
              required
              :value="
                new Date(newPayment.bankOperationDate)
                  .toLocaleDateString()
                  .split(' ')[0]
              "
              type="text"
              disabled
              class="font-medium mt-1 px-4 py-1 focus:outline-none focus:border-dark-gray block w-full  rounded-full shadow-md lg:text-lg"
            />
          </div>
          <div
            v-if="
              newPayment.fileURL &&
                newPayment.fileURL.split('.').pop() !== 'pdf'
            "
          >
            <label class="block text-sm font-light px-3 text-gray-700"
              >Comprobante de pago
            </label>
            <img
              :src="newPayment.fileURL"
              class="lg:mt-2 mt-1 px-3 lg:px-0 w-full h-60 object-contain"
              alt="Comprobante de pago"
              @click="isOpen = true"
            />
          </div>
        </div>
      </div>
      <div v-else class="mt-6 flex flex-col justify-center items-center">
        <div>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="false"
            data-icon="box-open"
            class="svg-inline--fa fa-box-open fa-w-20 h-60 w-60"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
          >
            <path
              fill="#4B5563"
              d="M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64 69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z"
            ></path>
          </svg>
        </div>
        <div>
          <h2 class="font-medium mt-16 mb-4 px-12 lg:self-start text-gray-600">
            Todavía no ha hecho una compra...
          </h2>
        </div>
      </div>
    </div>
    <ImageViewer
      v-if="isOpen"
      :imageUrl="newPayment.fileURL"
      @close="isOpen = false"
      class="absolute inset-0 z-10"
    />
    <TransitionRoot appear :show="isModalOpen" as="template">
      <Dialog as="div" @close="closeModal">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0 bg-black bg-opacity-75" />
            </TransitionChild>

            <span class="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  <div class="pl-2 flex items-center justify-between">
                    <h2 class="text-2xl font-medium text-gray-900">
                      El pago fue cargado correctamente
                    </h2>
                  </div>
                </DialogTitle>
                <div class="mt-4">
                  <div class="flex justify-center">
                    <img
                      src="icons/check-circle-regular.svg"
                      alt=""
                      width="100"
                      height="100"
                    />
                  </div>
                  <button
                    class="my-4 p-4 py-2 w-full text-center font-medium text-white bg-aces border border-transparent rounded-full focus:outline-none uppercase lg:text-lg"
                    @click="
                      () => {
                        isModalOpen = false;
                      }
                    "
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script>
import { ref } from "vue";
import axios from "axios";
import {
  TabGroup,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import Bancos from "./Bancos.vue";
import ImageViewer from "./ImageViewer.vue";
export default {
  components: {
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    Bancos,
    ImageViewer,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    SelectorIcon,
    CheckIcon,
  },
  props: {
    authenticatedUser: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const isOpen = ref(false);
    const isLoading = ref(true);
    const isEditable = ref(false);
    const existsPayment = ref(false);
    const confirmMPPayment = ref(false);
    const orderId = ref(null);
    const isShowBancos = ref(false);
    const total = ref(0);
    const newPayment = ref({});
    const chargeButton = ref(false);
    const banks = [
      "HSBC",
      "Provincia",
      "Nación",
      "Macro",
      "Galicia",
      "Santander",
    ];
    const selectedBank = ref(banks[0]);
    const paymentTypes = {
      0: "Depósito",
      1: "Transferencia",
      2: "Efectivo",
      3: "Mercado Pago",
      4: "Campo",
    };

    const isModalOpen = ref(false);

    const fetch = async () => {
      await axios
        .patch(
          `Orders/isEditable?churchClientId=${props.authenticatedUser.churchClientId}&clientId=${props.authenticatedUser.clientId}`
        )
        .then((res) => {
          isEditable.value = res.data.isEditable;
          isLoading.value = false;
          orderId.value = res.data.orderId;
          total.value = res.data.total;
        })
        .catch((err) => console.log(err.response));
      if (orderId.value != null) {
        await axios
          .get(`Payments/byOrder?orderId=${orderId.value}`)
          .then((res) => {
            newPayment.value = res.data;
            existsPayment.value = res.status === 200;
            confirmMPPayment.value =
              res.data.type === 3 && res.data.state === 1;
          });
        if (!existsPayment.value && isEditable.value) {
          await axios
            .get(`Payments/generate?orderId=${orderId.value}`)
            .then((res) => {
              newPayment.value = res.data;
            });
        }
      }
    };

    const formatBody = () => {
      newPayment.value.bank = selectedBank.value;
      newPayment.value.orderId = orderId.value;
      const file = document.getElementById("file").files[0];
      // eslint-disable-next-line no-undef
      const formData = new FormData();
      Object.entries(newPayment.value).forEach(([k, v]) => {
        if (v === null) formData.append(k, "");
        else formData.append(k, v);
      });
      formData.append("file", file);
      return formData;
    };
    const post = async () => {
      chargeButton.value = true;
      const body = formatBody();
      const res = await axios
        .post(`Payments/create`, body)
        .then((res) => {
          isModalOpen.value = true;
          fetch();
          chargeButton.value = false;
          return res;
        })
        .catch((err) => {
          fetch();
          chargeButton.value = false;
          return err;
        });
      return res;
    };

    setTimeout(() => {
      fetch();
    }, 1000);

    const removeScript = () => {
      console.log("rem");
      if (document.getElementById("checkout"))
        return document
          .getElementById("app")
          .removeChild(document.getElementById("checkout"));
    };

    const generateScript = async () => {
      // Aca se genera el Script de MercadoLibre
      if (total.value === 0) return;
      const { data } = await axios
        .patch(`Payments/searchPreferenceId?orderId=${orderId.value}`)
        .catch((err) => console.log(err.response));
      const initCheckout = document.createElement("script");
      initCheckout.id = "checkout";
      initCheckout.type = "text/javascript";
      // el id del checkout va a ser un valor dinamico
      initCheckout.innerHTML = `
          var checkout = mp.checkout({
            preference: {
              id: "${data}",
            },
          });`;
      const app = document.getElementById("app");
      app.appendChild(initCheckout);
    };

    return {
      removeScript,
      generateScript,
      existsPayment,
      confirmMPPayment,
      paymentTypes,
      orderId,
      isEditable,
      isLoading,
      chargeButton,
      post,
      isOpen,
      isShowBancos,
      total,
      banks,
      selectedBank,
      formatBody,
      newPayment,

      isModalOpen,

      async savePayment() {
        await post();
      },
    };
  },
};
</script>
<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="submit"],
button {
  -webkit-appearance: none;
}
</style>
