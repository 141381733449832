<template>
  <div class="container mx-auto bg-background min-h-screen">
    <TransitionRoot appear :show="isOpen" as="template">
      <Dialog as="div">
        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="min-h-screen px-4 text-center bg-black bg-opacity-40">
            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <DialogOverlay class="fixed inset-0" />
            </TransitionChild>

            <TransitionChild
              as="template"
              enter="duration-300 ease-out"
              enter-from="opacity-0 scale-95"
              enter-to="opacity-100 scale-100"
              leave="duration-200 ease-in"
              leave-from="opacity-100 scale-100"
              leave-to="opacity-0 scale-95"
            >
              <div
                class="inline-block w-full max-w-md p-6 my-48 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl"
              >
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900 text-center"
                >
                  Instalar
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Esta app es instalable, si asi lo desea puede hacerlo para
                    una mejor experiencia de usuario.
                  </p>
                </div>

                <div class="mt-4 flex justify-around">
                  <button
                    type="button"
                    class="flex flex-row justify-center px-3 p-1 text-sm font-medium text-white bg-aces rounded-full hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 uppercase lg:text-lg"
                    @click="instalar(true)"
                  >
                    Instalar
                  </button>
                  <button
                    type="button"
                    class="flex flex-row justify-center px-3 p-1 text-sm font-medium text-dark-gray bg-light-gray rounded-full hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 uppercase lg:text-lg"
                    @click="instalar(false)"
                  >
                    No ahora mismo
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
    <Stepper
      :currentStep="currentStep"
      @changeStep="changeStep"
      :authenticatedUser="authenticatedUser"
    />
    <CheckUser
      v-if="currentStep <= 1"
      :currentStep="currentStep"
      :authenticatedUser="authenticatedUser"
      @update="saveAuthenticatedUser"
      @changeStep="changeStep"
    />
    <ListaSubscripciones
      v-if="currentStep == 2"
      :authenticatedUser="authenticatedUser"
      @changeStep="changeStep"
    />
    <CartView
      v-if="currentStep == 3"
      :authenticatedUser="authenticatedUser"
      @changeStep="changeStep"
    />
    <PaymentSection
      v-if="currentStep == 4"
      :authenticatedUser="authenticatedUser"
      @changeStep="changeStep"
    />
    <!-- <OrderBlocked></OrderBlocked> -->
  </div>
</template>

<script>
import ListaSubscripciones from "./components/ListaSubscripciones.vue";
import CartView from "./components/CartView.vue";
import CheckUser from "./components/CheckUser.vue";
import Stepper from "./components/Stepper.vue";
// import OrderBlocked from "./components/OrderBlocked.vue";
import { ref } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import axios from "axios";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
import PaymentSection from "./components/PaymentSection.vue";
export default {
  name: "App",
  components: {
    // OrderBlocked,
    ListaSubscripciones,
    CheckUser,
    Stepper,
    CartView,
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
    PaymentSection,
  },
  setup() {
    const isOpen = ref(false);
    const deferredPrompt = ref(null);
    const authToken = localStorage.getItem("token");

    const tokenize = async () => {
      const { data: token } = await axios
        .post(`Auth/authenticate?appName=${process.env.VUE_APP_NAME}`)
        .catch((err) => {
          if (err.response.status) return (this.userNotFound = true);
        });

      //lo seteo en el localStorage
      localStorage.setItem("token", token);
      //Setter de el token como axios config Global!
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    };
    const pwaConfig = () => {
      if ("serviceWorker" in navigator) {
        // Use the window load event to keep the page load performant
        navigator.serviceWorker.register("service-worker.js");

        if (!JSON.parse(localStorage.getItem("alreadyAskedInstallation"))) {
          window.addEventListener("beforeinstallprompt", (e) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            deferredPrompt.value = e;
            isOpen.value = true;
          });
        }
      }
    };
    //si no esta guardado el token lo pido al servidor
    if (authToken == null) {
      tokenize();
    } else {
      axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    }
    pwaConfig();

    return {
      isOpen,
      deferredPrompt,
    };
  },
  data() {
    return {
      authenticatedUser: {},
      currentStep: 0,
    };
  },
  mounted() {
    localStorage.removeItem("cartProducts");
    this.authenticatedUser =
      JSON.parse(localStorage.getItem("authenticatedUser")) ?? {};
    if (!this.authenticatedUser?.documentNumber) return (this.currentStep = 0);
    this.currentStep = Number(localStorage.getItem("currentStep")) || 1;
  },
  unmounted() {
    localStorage.removeItem("currentStep");
  },
  methods: {
    changeStep(step) {
      if (!this.authenticatedUser?.documentNumber && step > 1) return;
      this.currentStep = step;
      localStorage.setItem("currentStep", step);
    },
    async saveAuthenticatedUser(user) {
      this.authenticatedUser = user;
      localStorage.setItem("authenticatedUser", JSON.stringify(user));
      this.changeStep(2);
    },
    async instalar(choice) {
      this.isOpen = false;
      localStorage.setItem("alreadyAskedInstallation", true);
      if (choice) this.deferredPrompt.prompt();
      this.deferredPrompt = null;
    },
  },
};
</script>

<style>
#app {
  font-family: Lato;
}
.top-50 {
  top: 50%;
}
.button {
  -webkit-appearance: none;
}
</style>
