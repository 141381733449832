<template>
  <div class="-mt-9 lg:mx-32 bg-background rounded-t-xl relative">
    <div :class="[isLoading == true ? 'pt-52 lg:pt-96' : '']">
      <div
        :class="[
          isLoading == true
            ? ' loader my-auto mx-auto  ease-linear rounded-full border-8 border-t-8 h-32 w-32 lg:h-64 lg:w-64'
            : '',
        ]"
      ></div>
    </div>
    <div
      :class="[
        isLoading == false
          ? 'opacity-100 transition duration-100 ease-in-out'
          : 'opacity-0 transition duration-1000 ease-in-out',
      ]"
    >
      <p class=" font-medium p-4 text-center bg-white rounded-xl ">
        {{
          orderId == null
            ? "Tu carrito está vacío. Vuelve al paso 2 para cargar productos."
            : "Antes de hacer tu pedido, revisá si tus pedidos se han cargado correctamente. Luego podés proceder a guardar tu pedido de suscripción."
        }}
      </p>
      <div class="lg:grid lg:grid-cols-2">
        <div
          v-for="prod in cart"
          :key="prod.id"
          class="text-sm text-gray-700 m-3"
        >
          <div
            v-if="prod.quantity"
            class="flex justify-between rounded-xl bg-white shadow-lg"
          >
            <div
              v-if="editSubscription"
              class="bg-red-warning rounded-l-lg flex flex-col justify-center p-1 text-white opacity-60 transition-all ease-in-out duration-500 cursor-pointer"
              @click="removeFromCart(prod)"
            >
              <img src="icons/times-circle.svg" alt="" class="w-4 h-4" />
            </div>
            <div class="p-2 w-3/4">
              <div
                :title="prod.ecommerceName"
                class="flex p-1 font-semibold text-lg lg:text-lg"
              >
                {{ prod.ecommerceName }}
              </div>
              <div class="flex items-baseline font-semibold">
                <p class=" p-1 font-light lg:">Total:</p>
                <p class="flex-grow text-lg lg:text-lg lg:flex-grow-0">
                  ${{ prod.unitPrice * prod.quantity }}
                </p>
                <span class="relative inline-block">
                  <span
                    class="absolute -top-2 right-0 inline-flex items-center justify-center h-4 w-6 text-xs font-bold leading-none text-dark-gray bg-light-gray transform translate-x-1/2 -translate-y-1/2 rounded-full lg:left-0 lg: lg:w-8 lg:h-6 "
                    >x{{ prod.quantity }}</span
                  >
                </span>
              </div>
            </div>
            <div class="w-1/4 flex justify-end">
              <img
                v-if="!!prod.image"
                :src="prod.image"
                class="rounded-r-lg h-20 w-16 lg:h-28 lg:w-20"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-center m-4">
        <div
          class="mx-12 m-2 flex items-baseline justify-center bg-light-gray px-2 p-1 rounded-full gap-2 truncate"
        >
          Total Suscripción:
          <p class="text-lg font-semibold lg:text-xl">${{ total }}</p>
        </div>
        <button
          v-if="isEditable && total != 0 && !editSubscription"
          type="button"
          class="p-2 my-2 text-center text-lg font-medium text-white bg-aces rounded-full hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 uppercase lg:text-xl "
          @click="startEdit"
        >
          Editar Suscripción
        </button>
        <button
          v-if="editSubscription"
          type="button"
          class="p-2 my-2 text-center text-lg font-medium text-white bg-aces rounded-full hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 uppercase lg:text-xl"
          @click="createSubscription"
        >
          Guardar Suscripción
        </button>
        <button
          v-if="isEditable && cart.length > 0 && !editSubscription"
          type="button"
          class="p-2 my-2 text-center text-lg font-medium text-white bg-aces rounded-full hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 uppercase lg:text-xl"
          @click="this.$emit('changeStep', 4)"
        >
          Pagar Suscripción
        </button>
        <button
          v-else-if="!editSubscription"
          type="button"
          class="p-2 my-2 text-center text-lg font-medium text-dark-gray bg-light-gray rounded-full hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 uppercase cursor-not-allowed lg:text-xl"
          disabled
        >
          Guardar Suscripción
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";

export default {
  props: {
    authenticatedUser: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const isShowBancos = ref(false);
    const isLoading = ref(false);
    const isOpen = ref(false);

    const isEditable = ref(false);
    const orderId = ref(0);
    const cart = ref([]);
    const total = ref(null);
    const editSubscription = ref(false);

    isLoading.value = true;
    const getCart = async () => {
      await axios
        .patch(
          `Orders/isEditable?churchClientId=${props.authenticatedUser.churchClientId}&clientId=${props.authenticatedUser.clientId}`
        )
        .then((res) => {
          isEditable.value = res.data.isEditable;
          isLoading.value = false;
          orderId.value = res.data.orderId;
          total.value = res.data.total;
        });
      await axios
        .get("Periods/getCurrent")
        .then(async ({ data }) => {
          await axios
            .get(
              `Products/byOrder?periodId=${data.periodId}${
                orderId.value == null ? "" : `&orderId=${orderId.value}`
              }`
            )
            .then((res) => {
              cart.value = res.data
                .filter((x) => x.quantity != 0)
                .sort((a, b) => {
                  //ordeno la lista del carrito por sequence
                  if (a.Sequence > b.Sequence) {
                    return 1;
                  }
                  if (a.Sequence < b.Sequence) {
                    return -1;
                  }
                  return 0;
                });
            })
            .catch((err) => console.log(err.response));
        })
        .catch((err) => console.log(err.response));
    };

    setTimeout(() => {
      getCart();
    }, 1000);

    return {
      orderId,
      isShowBancos,
      isLoading,
      cart,
      total,
      isEditable,
      isOpen,
      editSubscription,
      startEdit() {
        editSubscription.value = true;
      },
      finishEdit() {
        editSubscription.value = false;
      },
      closeModal() {
        isOpen.value = false;
      },
      openModal() {
        getCart();
        setTimeout(() => {
          isShowBancos.value = false;
          isOpen.value = true;
        }, 1000);
      },
      removeFromCart(prod) {
        prod.quantity = 0;
        prod.amount = 0;
        this.total = this.cart.reduce((acc, curr) => acc + curr.amount, 0);
      },
      async createSubscription() {
        this.finishEdit();
        await axios
          .put(
            `Orders/upsert?orderId=${orderId.value}
          &subscriptorId=${props.authenticatedUser.clientId}`,
            cart.value
          )
          .then(() => {
            this.openModal();
          })
          .catch((err) => console.log(err.response));
      },
    };
  },
};
</script>