<template>
  <!-- <div class="absolute top-3">SAPEE</div> -->
  <div class="-mt-9 lg:mx-32 bg-background rounded-t-xl block relative">
    <div :class="[isLoading == true ? 'pt-52 lg:pt-96' : '']">
      <div
        :class="[
          isLoading == true
            ? ' loader my-auto mx-auto  ease-linear rounded-full border-8 border-t-8 h-32 w-32 lg:h-64 lg:w-64'
            : '',
        ]"
      ></div>
    </div>
    <div
      :class="[isLoading == false ? 'opacity-100' : 'opacity-0']"
      class="transition duration-1000 ease-in-out"
    >
      <TransitionRoot appear :show="isOpen" as="template">
        <Dialog
          as="div"
          @close="closeModal"
          :open="isOpen"
          v-model="selectedProduct"
        >
          <div
            class="fixed flex justify-center inset-0 z-30 overflow-y-auto bg-black bg-opacity-70"
          >
            <div class="min-h-screen px-4 text-center">
              <TransitionChild
                as="template"
                enter="duration-400 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-400 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <DialogOverlay class="fixed inset-0" />
              </TransitionChild>

              <span class="inline-block mt-20 align-middle" aria-hidden="true">
                &#8203;
              </span>

              <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0 scale-95"
                enter-to="opacity-100 scale-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100 scale-100"
                leave-to="opacity-0 scale-95"
              >
                <div
                  class="-mt-20 lg:leading-loose text-sm lg:text-base h-content w-full max-w-xs p-6 my-8 overflow-hidden text-left 
                  transition-all transform bg-white shadow-xl rounded-2xl flex flex-col lg:max-w-xl lg:p-6"
                >
                  <DialogTitle
                    as="h3"
                    class="px-2 text-lg font-medium leading-3 pb-2 text-gray-900 text-center max-w-xs "
                  >
                    {{ selectedProduct.ecommerceName }}
                  </DialogTitle>

                  <!-- Simple image -->
                  <div
                    v-if="!selectedProduct?.isMultipleProducts"
                    class="mt-2 flex-grow flex justify-center lg:my-4 h-full"
                  >
                    <div class="flex flex-col w-64">
                      <img
                        v-if="!!selectedProduct.image"
                        :src="selectedProduct.image"
                        loading="lazy"
                        class="my-3 -mt-3 object-contain h-60"
                      />
                      <p class="pl-1 text-sm text-gray-600">
                        {{ selectedProduct.description }}
                      </p>
                    </div>
                  </div>
                  <!-- Multiple Images -->
                  <div
                    v-else-if="selectedProduct?.isMultipleProducts"
                    class="flex flex-row overflow-x-scroll gap-4 scroll-snap-x"
                  >
                    <div
                      v-for="p in multipleImages"
                      :key="p.image"
                      class="mt-2 justify-center lg:my-4 scroll-snap-align"
                    >
                      <div class="flex flex-col w-64">
                        <img
                          v-if="!!p.image"
                          :src="p.image"
                          loading="lazy"
                          class="w-full px-16 h-auto "
                        />
                        <p class="pl-1 text-xs text-gray-600">
                          {{ p.description }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    class="my-3 rounded-full items-center flex justify-between bg-light-gray"
                  >
                    <div class="mx-4 p-1 font-semibold  lg:p-2 truncate">
                      Unidades
                      <i class="font-light"
                        >(${{ selectedProduct.unitPrice }})</i
                      >
                    </div>
                    <div
                      v-if="isEditable"
                      class="p-1 flex flex-row h-full rounded-full w-24 bg-dark-gray text-white lg:p-2"
                    >
                      <button
                        class="w-1/3 cursor-pointer focus:outline-none "
                        @click="changeQuantity('-')"
                      >
                        <span class="m-auto text-lg font-semibold">−</span>
                      </button>
                      <input
                        v-model="selectedProduct.quantity"
                        type="number"
                        class="bg-dark-gray outline-none focus:outline-none text-center w-1/3 font-semibold  focus:text-black  flex items-center text-gray-700"
                        readonly
                      />
                      <button
                        class=" w-1/3 cursor-pointer focus:outline-none"
                        @click="changeQuantity('+')"
                      >
                        <span class="m-auto text-lg font-semibold">+</span>
                      </button>
                    </div>
                    <div
                      v-else
                      class="p-1 flex flex-row h-full rounded-full w-24 bg-dark-gray text-white lg:p-2"
                    >
                      <button
                        class="w-1/3 cursor-disabled focus:outline-none cursor-not-allowed"
                        disabled
                      >
                        <span class="m-auto text-lg font-semibold">−</span>
                      </button>
                      <input
                        v-model="selectedProduct.quantity"
                        type="number"
                        class="bg-dark-gray outline-none focus:outline-none text-center w-1/3 font-semibold   md:text-lg flex items-center text-gray-700 cursor-not-allowed"
                        readonly
                      />
                      <button
                        class=" w-1/3 cursor-disabled focus:outline-none cursor-not-allowed"
                        disabled
                      >
                        <span class="m-auto text-lg font-semibold">+</span>
                      </button>
                    </div>
                  </div>
                  <div>
                    <div
                      class="py-1 flex flex-row justify-center w-full  font-medium text-white bg-aces rounded-full hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 uppercase truncate"
                      disabled
                      v-if="disabledCloseButton"
                    >
                      <span v-if="selectedProduct.quantity > 0">
                        Agregar
                        <i class="font-light px-2">
                          (${{
                            selectedProduct.quantity *
                              selectedProduct.unitPrice
                          }})
                        </i>
                      </span>
                      <span v-else>
                        Volver
                      </span>
                    </div>
                    <button
                      v-else-if="
                        isEditable &&
                          selectedProduct.quantity != 0 &&
                          !disabledCloseButton
                      "
                      type="button"
                      class="py-1 flex flex-row justify-center w-full  font-medium text-white bg-aces rounded-full hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 uppercase truncate"
                      @click="closeModal()"
                    >
                      <span>
                        Agregar
                        <i class="font-light px-2">
                          (${{
                            selectedProduct.quantity *
                              selectedProduct.unitPrice
                          }})
                        </i>
                      </span>
                    </button>
                    <button
                      v-if="
                        selectedProduct.quantity == 0 &&
                          isEditable &&
                          !disabledCloseButton
                      "
                      type="button"
                      class="p-1 flex flex-row justify-center w-full  font-medium text-white bg-aces rounded-full hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 uppercase truncate"
                      @click="closeModal()"
                    >
                      Volver
                    </button>

                    <button
                      v-else-if="!isEditable"
                      type="button"
                      class="p-1 flex flex-row justify-center w-full font-medium text-dark-gray bg-light-gray rounded-full hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 uppercase"
                      @click="isOpen = false"
                    >
                      Volver
                    </button>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </TransitionRoot>
      <p class=" font-medium p-4 text-center bg-white rounded-t-xl lg:text-lg">
        Elige los materiales que desees para realizar tu pedido de suscripción
      </p>
      <div class="md:col-span-1 sticky top-0 bg-white shadow-md z-20">
        <div class="flex flex-row whitespace-nowrap overflow-x-auto ">
          <div
            v-for="cat in subscriptionCategories"
            :key="cat.categoryId"
            :class="[
              cat.categoryName == selectedCategory
                ? 'bg-dark-gray text-white font-medium'
                : 'bg-light-gray text-dark-gray',
            ]"
            class="m-1 my-2 px-2 rounded-full transition duration-1000 ease-in-out lg:px-6 cursor-pointer"
          >
            <div
              @click="goto(cat.categoryName)"
              class="text-lg lg:text-2xl cursor-pointer"
            >
              {{ cat.categoryName }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-full p-2 mt-2 mx-auto bg-background max-h-full overflow-y-auto"
      >
        <div v-for="cat in subscriptionCategories" :key="cat.categoryId">
          <div class="focus:bg-black " :ref="cat.categoryName">
            <h2 class="text-lg text-center font-semibold p-1 lg:text-2xl">
              {{ cat.categoryName }}
            </h2>
            <!-- v-show="prod.categoryId == cat.categoryId" -->
            <div
              v-for="prod in subscriptionProducts.filter(
                (x) => x.categoryId == cat.categoryId
              )"
              :key="prod.productId"
              class="text-dark-gray m-2 h-40 cursor-pointer"
              @click="openModal(prod.productId)"
            >
              <div
                class="flex justify-between rounded-xl bg-white shadow-lg my-2 "
              >
                <div class="p-3 flex-shrink-0 w-4/6">
                  <div class="font-semibold truncate">
                    {{ prod.ecommerceName }}
                  </div>
                  <div
                    class="text-sm font-extralight lg:text-lg"
                    :title="prod.description"
                  >
                    {{ prod.description?.substr(0, 60).concat("...") }}
                  </div>
                  <div
                    class="text-dark-gray font-semibold text-xl mt-3 flex justify-between lg:text-xl"
                  >
                    ${{ prod.unitPrice }}
                    <span
                      v-if="prod.quantity > 0"
                      class=" relative inline-block"
                    >
                      <img
                        src="icons/shopping-cart-solid.svg"
                        alt=""
                        color="black"
                        class="mr-2 mt-1 w-6 h-5"
                      />
                      <span
                        class="absolute top-0 right-0 inline-flex items-center justify-center h-4 w-4 text-sm font-bold leading-none text-dark-gray bg-light-gray transform translate-x-1/2 -translate-y-1/2 rounded-full"
                        >{{ prod.quantity }}</span
                      >
                    </span>
                  </div>
                </div>
                <div class="flex flex-grow justify-end">
                  <img
                    v-if="!!prod.image"
                    :src="prod.image"
                    loading="lazy"
                    class="rounded-r-lg h-40 w-auto object-contain"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="fixed flex flex-row items-center justify-around gap-1 bottom-6 inset-x-1/2 transform -translate-x-1/2 px-6 py-2 text-center text-xl font-medium text-white bg-aces rounded-full lg:text-xl focus:outline-none truncate w-2/3 md:w-80"
          @click="goToCart"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="shopping-cart"
            class="svg-inline--fa fa-shopping-cart fa-w-18 mr-2 h-4 lg:h-6"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path
              fill="white"
              d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z"
            ></path>
          </svg>
          Ver mi Carrito
          <i class="font-light">(${{ total }})</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ref, watchEffect } from "vue";
import {
  TransitionRoot,
  TransitionChild,
  Dialog,
  DialogOverlay,
  DialogTitle,
} from "@headlessui/vue";
import { createToast } from "mosha-vue-toastify";

export default {
  components: {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogOverlay,
    DialogTitle,
  },
  setup(props) {
    const isOpen = ref(false);
    const isLoading = ref(false);
    const subscriptionCategories = ref([]);
    const subscriptionProducts = ref([]);
    const selectedProduct = ref(null);
    const multipleImages = ref([]);
    const total = ref(0);
    const isEditable = ref(false);
    const orderId = ref(null);
    const disabledCloseButton = ref(false);
    isLoading.value = true;

    const initialFetch = async () => {
      await axios
        .get(`Categories/all`)
        .then((res) => (subscriptionCategories.value = res.data))
        .catch((err) => console.log(err.response));

      await axios
        .patch(
          `Orders/isEditable?churchClientId=${props.authenticatedUser.churchClientId}&clientId=${props.authenticatedUser.clientId}`
        )
        .then((res) => {
          isEditable.value = res.data.isEditable;
          isLoading.value = false;
          orderId.value = res.data.orderId;
        })
        .catch((err) => console.log(err.response));
      fetchCartDB();
    };

    const fetchCartDB = async () => {
      const {
        data: { periodId },
      } = await axios.get("Periods/getCurrent");

      const res = await axios.get(
        `Products/byOrder?periodId=${periodId}${
          orderId.value == null ? "" : `&orderId=${orderId.value}`
        }`
      );

      subscriptionProducts.value = res.data;
      return res;
    };

    const checkoutCart = async () => {
      const cart = [
        ...subscriptionProducts.value.filter(
          (x) => x.quantity != 0 || x.orderDetailId != 0
        ),
      ];
      if (cart.length == 0) return;
      await axios
        .put(
          `Orders/upsert?${
            orderId.value == null ? "" : `orderId=${orderId.value}&`
          }subscriptorId=${props.authenticatedUser.clientId}`,
          cart
        )
        .then(async () => {
          if (orderId.value == null || total.value == 0) {
            //si es la primera vez que creo un pedido o si lo estoy vaciando pido de nuevo los datos de IsEditable
            await axios
              .patch(
                `Orders/isEditable?churchClientId=${props.authenticatedUser.churchClientId}&clientId=${props.authenticatedUser.clientId}`
              )
              .then((res) => {
                isEditable.value = res.data.isEditable;
                orderId.value = res.data.orderId;
              });
            fetchCartDB();
          } else {
            fetchCartDB();
          }
        })
        .catch((err) => console.log(err.response));
    };

    watchEffect(() => {
      const t = subscriptionProducts.value
        .filter((x) => x.amount != 0)
        .reduce((acc, curr) => acc + curr.amount, 0);
      total.value = t;
    });
    watchEffect(async () => {
      if (selectedProduct.value?.isMultipleProducts == 1) {
        await axios
          .get(`Products/related?productId=${selectedProduct.value.productId}`)
          .then((res) => {
            multipleImages.value = res.data;
          })
          .catch((err) => console.log(err.response));
      }
    });

    setTimeout(() => {
      initialFetch();
    }, 1000);

    return {
      subscriptionCategories,
      subscriptionProducts,
      selectedProduct,
      total,
      isEditable,
      isLoading,
      isOpen,
      multipleImages,
      disabledCloseButton,
      async closeModal() {
        if (!isEditable.value) return (isOpen.value = false);
        disabledCloseButton.value = true;
        await checkoutCart();
        isOpen.value = false;
        disabledCloseButton.value = false;
      },
      openModal(p) {
        isOpen.value = true;
        selectedProduct.value = subscriptionProducts.value.find(
          (e) => e.productId === p
        );
        if (!isEditable.value) {
          createToast("Ya no es posible modificar su pedido.", {
            position: "top-center",
            type: "danger",
            transition: "zoom",
          });
        }
      },
      changeQuantity(operation) {
        console.log(operation);
        if (operation == "-" && selectedProduct.value.quantity !== 0)
          this.selectedProduct.quantity -= 1;
        if (operation == "+") selectedProduct.value.quantity += 1;
        selectedProduct.value.amount =
          selectedProduct.value.unitPrice * selectedProduct.value.quantity;

        console.log(selectedProduct.value.quantity);

        subscriptionProducts.value.find(
          (e) => e.productId === selectedProduct.value.productId
        ).quantity = selectedProduct.value.quantity;
        subscriptionProducts.value.find(
          (e) => e.productId === selectedProduct.value.productId
        ).amount = selectedProduct.value.amount;
        console.log(subscriptionProducts.value);
      },
    };
  },
  props: {
    authenticatedUser: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedCategory: "",
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const f = this.subscriptionCategories.filter(
        (x) =>
          window.scrollY > this.$refs[x.categoryName].offsetTop - 130 &&
          window.scrollY <
            this.$refs[x.categoryName]?.offsetHeight +
              this.$refs[x.categoryName]?.offsetTop -
              90
      );
      this.selectedCategory = f[0]?.categoryName ?? null;
    },
    goto(refName) {
      window.scrollTo({
        top: this.$refs[refName]?.offsetTop - 50,
        behavior: "smooth",
      });
    },
    goToCart() {
      this.$emit("changeStep", 3);
    },
  },
};
</script>
<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="submit"],
button {
  -webkit-appearance: none;
}
</style>
